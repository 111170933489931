html {
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
	margin:0;
  padding:0;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url('https://themes.googleusercontent.com/static/fonts/opensans/v5/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff') format('woff');
}

.containerRecommended {
  width: 100%;
  position: relative;
  transform: translate(-19%);
}

.mobileContainerRecommended {
  width: 100vw;
  position: relative;
  right: 13%;
}

.rectHover:hover {
  display: none;
}

.zoomButton {
  font-size: 37px;
  font-weight: 200;
  width: 50px;
  height: 50px;
}

.mobileZoomButton {
  height: 42px;
  width: 50px;
  font-size: 25px;
}

.gradIndicator {
  transition: all 0.1s ease;
}

.overlayExit {
  border: none;
  color: #FFFFFF;
  background-color: transparent;
  padding: 0;
  left: 49%;
  top: 45vh;
  transform: translate(-50%);
}

.searchExitButton {
  height: 12px;
  width: 12px;
  position: relative;
  border: none;
  background-color: transparent;
  padding: 0;
  left: 49%;
  transform: translate(-50%, -20vh);
}

.hebMobileCloseButton, .mobileCloseButton, .hebProjectExitButton, .projectExitButton, .overlayExit, .depOverlayExit {
  height: 21px;
  width: 21px;
  position: relative;
  opacity: 0.6;
  font-size: 45px;
  font-family: 'open sans', arial, sans-serif;
  font-weight: lighter;
}

.depOverlayExit {
  border: none;
  color: #FFFFFF;
  background-color: transparent;
  padding: 0;
  left: 49%;
  transform: translate(-50%);
}

.projectExitButton {
  left: 96%;
}

.hebProjectExitButton {
  left: 2%;
}

.mobileCloseButton {
  left: 85%;
}

.hebMobileCloseButton {
  left: 5%;
}

.projectExitButton, .mobileCloseButton, .hebMobileCloseButton, .hebProjectExitButton, .searchExitButton, .zoomButton, 
.mobileZoomButton, .galleryButton, .invisButton, .searchBarButton {
  border: none;
  color: #707070;
  background-color: transparent;
  padding: 0;
}

.zoomButton:focus, .mobileZoomButton:focus, .projectExitButton:focus, 
.hebProjectExitButton:focus, .galleryButton:focus, .overlayExit:focus,
.depOverlayExit:focus, .hebMobileCloseButton:focus, .mobileCloseButton:focus,
.invisButton:focus, .searchExitButton:focus, .searchBarButton:focus{
  outline: 0;
  box-shadow: none !important;
}

.mobileSidebar {
  width: 100vw;
  min-height: 50px;
  position: fixed;
  background-color: #e9e8e8;
  top: 50px;
}

.departmentBox {
  text-align: center;
  font-weight: 900;
  line-height: 1.25;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

@media screen and (max-width: 1980px) and (min-width: 1700px){
  .hebSidebar, .engSidebar {
    height: 100vh;
    background-color: #e9e8e8;
    width: 4.3%;
    position: fixed;
    top: 3.17rem;
  }
  
  .maskHolder {
     width: 100%; 
     height: 100vh;
  }

  .depOverlayExit {
    top: 30vh;
  }

  .containerRecommended {
    width: 95%;
    position: relative;
    left: 0%;
    transform: translate(-21%);
  }
}

@media screen and (max-width: 1680px) and (min-width: 1500px){
  .hebSidebar, .engSidebar {
    height: 100vh;
    background-color: #e9e8e8;
    width: 4.3%;
    position: fixed;
    top: 3.17rem;
  }

  .depOverlayExit {
    top: 26vh;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1300px){
  .hebSidebar, .engSidebar {
    height: 100vh;
    background-color: #e9e8e8;
    width: 4.3%;
    position: fixed;
    top: 3.17rem;
  }

  .depOverlayExit {
    top: 26vh;
  }
}

@media screen and (max-width: 1280px){
  .hebSidebar, .engSidebar {
    height: 100vh;
    background-color: #e9e8e8;
    width: 4.3%;
    position: fixed;
    top: 3.2rem;
  }

  .depOverlayExit {
    top: 25vh;
  }
}

.engSidebar {
  right: 0;
}

.hebSidebar {
  left: 0;
}

.hebFlexBox {
  width: 95.7%;
  height: 100%;
  position: relative;
  top: 50px;
  float: right;
}

.flexBox {
  width: 95.7%;
  height: 100%;
  position: relative;
  top: 50px;
  margin-left: 50px;
  float: left;
}

.mobileFlexBox {
  width: 100vw;
  height: 100%;
  position: relative;
  top: 109px;
}

.header {
	background-color:#363636;
	height: 50px;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  top: 0;
  position: fixed;
  color: #FFFFFF;
  z-index: 2;
}

.selectButton {
  color: #FFFFFF;
  background-color: #626262 !important;
  height: 90%;
  margin: 0 !important;
  font-size: 15px;
  font-family: RAG-Sans;
  font-weight: 300;
  box-shadow: none !important;
  border-radius: 0;
}

.searchButton {
  background-color: #434343 !important;
  margin: 0 !important;
  font-size: 15px;
  border-radius: 0;
  box-shadow: none !important;
  padding-left: 17px;
}

.selectButton:hover, .searchButton:hover {
  box-shadow: none;
}

.selectedButton {
  background-color: #4D4D4D !important;
  box-shadow: none !important;
}

.rectFadeIn, .firstFadeOutHorizontal, .secondFadeOutHorizontal,
.firstFadeOutVertical, .secondFadeOutVertical {
  transition: all 0.5s ease-in-out 0s;
}

.rectFadeIn {
  transform: translate(0, 0);
}

.firstFadeOutHorizontal {
  transform: translate(0, -100%);
}

.firstFadeOutVertical {
  transform: translate(-100%, 0);
}

.secondFadeOutHorizontal {
  transform: translate(0, 100%);
}

.secondFadeOutVertical {
  transform: translate(100%, 0);
}

.maskFadeIn {
  opacity: 1;
  transition: all 0.5s ease-in-out 0s;
}

.maskFadeOut {
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
}

.textFadeOut {
  opacity: 0;
  transition: all 3.0s ease-in-out 0s;
}

.textFadeIn {
  opacity: 1;
  transition: all 3.0s ease-in-out 0s;
}

.depDivider {
  font-size: 25px;
  opacity: 0.5;
}

.speechBox {
  color: #4D4D4D;
  line-height: 1.2;
}

.speechBoxHolder {
  width: 60%;
  height: 60%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -40%);
}

.hebSpeechBoxHolder {
  width: 60%;
  height: 60%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -30%);
}

hr { 
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-left: none;
  border-width: 1.5px;
} 

.popup-content {
  height: 100%;
  width: 100vw !important;
  padding: 0 !important;
}

.page-footer {
  width: 100%;
  height: 450px;
  background: #363636 0% 0% no-repeat padding-box;
}

.hebFooterBox {
  float: left;
  width: 25%;
  height: 360px;
  position: relative;
}

.footerBox {
  float: right;
  width: 25%;
  height: 360px;
  position: relative;
}

.clear {
  clear: both;
}

.imagePicker {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.imagePicker:hover {
  cursor: pointer;
}

.imageCarousel {
  width: 100%;
}

.hiddenScroll::-webkit-scrollbar {
  display: none;
}

.hiddenScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.searchInput {
  outline: 0;
  width: 98%;
  border: none;
  font-size: 18px;
  color: #626262;
  font-weight: lighter;
}

.searchInput::placeholder { 
  color: #C1C1C1;
}

.searchInput:-ms-input-placeholder { 
  color: #C1C1C1;
}

.searchInput::-ms-input-placeholder { 
  color: #C1C1C1;
}

.searchBarButton {
  color: '#626262';
}

.searchbar {
  width: 100%;
  margin-top: 35px;
  border: solid;
  border-width: 0 0 1px;
  border-color: #777777
}

.searchbar:focus {
  border-color: #626262;
}

.topicOverlay, .depOverlay, .searchOverlay {
  position: fixed;
  height: calc(100vh - 50px);
  z-index: 1;
  transform: translateY(calc(-100vh - 50px));
}

.searchOverlay {
  opacity: 0.9;
  background-color: #FFFFFF;
}

.topicOverlay {
  background-color: #4D4D4D;
  -webkit-transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -o-transition: background-color 0.5s ease-in-out;
  -khtml-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}

.depOverlay {
  background-size: cover;
  background-repeat: no-repeat;
}

.depOverlayImage {
  background-image: url('./Images/Departments/default.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.topicLine {
  position: relative;
  width: max-content;
  min-height: 65px;
  left: 50%;
  transform: translate(-50%);
}

.topicContainer {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topicContainer {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.carousel .slide {
  background: #FFFFFF !important;
}

.picLoader {
  position: relative;
  width: 50%;
  left: 50%;
  top: 50%;
  margin-top: 50px;
}

.loading {
  font-size: 20px;
  font-weight: lighter;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 50px;
  color: #626262;
}

@import url("https://use.typekit.net/qwc3eog.css");

.arabicFont {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}


.slide-bottom, .slide-top {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-7-21 15:14:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }}

  /**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }
}

.fadePicIn {
animation: fadeIn ease 1.0s;
-webkit-animation: fadeIn ease 1.0s;
-moz-animation: fadeIn ease 1.0s;
-o-animation: fadeIn ease 1.0s;
-ms-animation: fadeIn ease 1.0s;
}

.fadePicOut {
  opacity: 0;
  animation: fadeOut ease 1.0s;
  -webkit-animation: fadeOut ease 1.0s;
  -moz-animation: fadeOut ease 1.0s;
  -o-animation: fadeOut ease 1.0s;
  -ms-animation: fadeOut ease 1.0s;
}

@keyframes fadeIn {
  100% {opacity:1;}
  0% {opacity:0;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-moz-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-webkit-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-o-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-ms-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}