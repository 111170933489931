
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'RAG-Sans';
  font-weight: 700;
  src: url(./fonts/RAG-Sans-1.1-Bold.woff) format('woff');
}

@font-face {
  font-family: 'RAG-Sans';
  font-weight: 200;
  src:  url(./fonts/RAG-Sans-1.1-Light.woff) format('woff');
}

@font-face {
  font-family: 'RAG-Sans';
  font-weight: 400;
  src: url(./fonts/RAG-Sans-1.1-SemiBold.woff) format('woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'RAG-Sans',
    monospace;
}

* {
  font-family: RAG-Sans;
}
